import Svg, { Path } from "react-native-svg";

const WavingHand = ({width, height}: any) => {
  return (
    <Svg
      width={width}
      height={height}
      viewBox="0 0 216 216"
      fill="none"
    >
      <Path
        d="M55.973 57.723c-2.905-5.078-9.377-6.84-14.455-3.934-5.078 2.905-6.84 9.376-3.935 14.454a10.582 10.582 0 001.7 2.226l34.598 45.3 8.064 10.163-23.671-30.994c-2.905-5.078-9.377-6.84-14.455-3.934-5.078 2.905-6.84 9.376-3.935 14.454a10.585 10.585 0 001.7 2.227l23.672 30.993 18.812 23.697c16.401 18.82 43.544 17.792 62.359 1.395a58.844 58.844 0 0019.546-36.931c1.158-12.698 2.421-45.903 2.421-45.903-.547-7.803-9.406-13.57-10.547-9.54l-14.682 29.272L133.068 88 92.673 36.376c-2.905-5.078-9.377-6.84-14.455-3.935-5.078 2.905-6.84 9.377-3.934 14.455a10.582 10.582 0 001.7 2.226l12.746 16.69L108 91.25 67.743 38.21c-2.905-5.079-9.377-6.84-14.455-3.935-5.078 2.905-6.84 9.376-3.934 14.454a10.591 10.591 0 001.7 2.227L95.068 108"
        fill="#E8B648"
      />
      <Path
        d="M55.973 57.723c-2.905-5.078-9.377-6.84-14.455-3.934-5.078 2.905-6.84 9.376-3.935 14.454a10.582 10.582 0 001.7 2.226l34.598 45.3 8.064 10.163-23.671-30.994c-2.905-5.078-9.377-6.84-14.455-3.934-5.078 2.905-6.84 9.376-3.935 14.454a10.585 10.585 0 001.7 2.227l23.672 30.993 18.812 23.697c16.401 18.82 43.544 17.792 62.359 1.395a58.844 58.844 0 0019.546-36.931c1.158-12.698 2.421-45.903 2.421-45.903-.547-7.803-9.406-13.57-10.547-9.54l-14.682 29.272L133.068 88 92.673 36.376c-2.905-5.078-9.377-6.84-14.455-3.935-5.078 2.905-6.84 9.377-3.934 14.455a10.582 10.582 0 001.7 2.226l12.746 16.69L108 91.25 67.743 38.21c-2.905-5.079-9.377-6.84-14.455-3.935-5.078 2.905-6.84 9.376-3.934 14.454a10.591 10.591 0 001.7 2.227L95.068 108"
        stroke="#000"
        strokeWidth={6}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M35.019 128.616c0 7.699 5.24 13.929 11.717 13.929M21.185 127.31c0 16.787 11.428 30.372 25.55 30.372M135.786 63.713c0-7.7-5.241-13.929-11.718-13.929M149.62 65.017c0-16.788-11.428-30.372-25.551-30.372"
        stroke="#000"
        strokeWidth={6}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  )
};

export default WavingHand;
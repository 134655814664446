import Svg, { Circle, Path } from "react-native-svg";

function BlockChat() {
  return (
    <Svg
      width={30}
      height={30}
      viewBox="0 0 30 30"
      fill="none"
    >
      <Circle cx={15} cy={15} r={15} fill="#E4E6EB" />
      <Path
        d="M15 13.975c-1.1 0-2-.35-2.7-1.05-.7-.7-1.05-1.6-1.05-2.7s.35-2 1.05-2.7c.7-.7 1.6-1.05 2.7-1.05s2 .35 2.7 1.05c.7.7 1.05 1.6 1.05 2.7s-.35 2-1.05 2.7c-.7.7-1.6 1.05-2.7 1.05zM8.5 22c-.417 0-.77-.146-1.063-.438A1.447 1.447 0 017 20.5v-.85c0-.634.158-1.175.475-1.625A3.05 3.05 0 018.7 17c1.117-.5 2.188-.875 3.213-1.125A12.987 12.987 0 0115 15.5c1.033 0 2.058.129 3.075.387 1.017.258 2.083.63 3.2 1.113.517.233.933.575 1.25 1.025.317.45.475.991.475 1.625v.85c0 .416-.146.77-.438 1.062A1.446 1.446 0 0121.5 22h-13z"
        fill="#050505"
      />
    </Svg>
  );
};

export default BlockChat;
import { View, _Text } from 'react-native';
import _Button from '../components/control/button';
import _TextInput from '../components/control/text-input';

const HomeScreen = (props: any, {navigation}:any) => {
    /*
    Erick: Add all content for the single page view here,
    If you need to make reusable components, create a folder
    in the components folder named "home" and add your component files there
    */
    return (
    <View>
        
    </View>
    );
};

export default HomeScreen;
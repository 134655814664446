import * as React from "react"
import Svg, { Circle, Path } from "react-native-svg"

function MuteChat() {
  return (
    <Svg
      width={30}
      height={30}
      viewBox="0 0 30 30"
      fill="none"
    >
      <Circle cx={15} cy={15} r={15} fill="#E4E6EB" />
      <Path
        d="M14.995 24.375c1.458 0 2.255-1.031 2.255-2.484h-4.514c0 1.453.797 2.484 2.26 2.484zm7.318-4.866c-.722-.951-2.142-1.51-2.142-5.77 0-4.373-1.932-6.131-3.732-6.553-.169-.042-.29-.099-.29-.277v-.136c0-.628-.516-1.157-1.144-1.153-.628-.01-1.144.525-1.144 1.153v.136c0 .174-.122.235-.29.277-1.805.426-3.732 2.18-3.732 6.553 0 4.26-1.42 4.814-2.142 5.77A.93.93 0 008.442 21h13.13c.764 0 1.205-.88.74-1.49z"
        fill="#050505"
      />
    </Svg>
  );
}

export default MuteChat;
import * as React from "react"
import Svg, { Circle, Path } from "react-native-svg"

function UnmuteChat() {
  return (
    <Svg
      width={30}
      height={30}
      viewBox="0 0 30 30"
      fill="none"
    >
      <Circle cx={15} cy={15} r={15} fill="#E4E6EB" />
      <Path
        d="M15 23.81c1.372 0 2.484-1.173 2.484-2.62h-4.968c.002 1.447 1.113 2.62 2.484 2.62zM9.742 12.047V17.3l-2.174 1.857v1.06h10.6L10.455 9.256a5.753 5.753 0 00-.713 2.791zM22.432 19.156l-2.162-1.858v-5.251c0-2.736-1.875-5.005-4.341-5.467v-.418c0-.36-.277-.653-.62-.653h-.618c-.343 0-.62.292-.62.653v.42a5.1 5.1 0 00-2.232 1.04l-1.56-2.218a.488.488 0 00-.706-.11.553.553 0 00-.104.745l1.62 2.305-.011.01 8.346 11.86h.018l1.583 2.248a.488.488 0 00.706.11.553.553 0 00.104-.744L20.7 20.215h1.731v-1.059z"
        fill="#050505"
      />
    </Svg>
  );
}

export default UnmuteChat;